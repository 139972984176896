/* Default styles */

.share {
    width: 60%!important;
    height: auto; /* Adjusted to auto for responsiveness */
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
    box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
    background-color: white;
    margin: 0 auto; /* Center the share container horizontally */
    background-color: var(--chat_post_back) !important;

}

.shareWrapper {
    padding: 10px;
}

.shareTop {
    display: flex;
    align-items: center;
    flex-wrap: wrap; /* Allow flex items to wrap */
}

.shareProfileImg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.shareInput {
    border: none;
    width: 80%; /* Adjusted to 100% for responsiveness */
    height: 100px;
    margin-top: 10px; /* Added margin for spacing */

    color: var(--chat_text_color) !important;
    background-color: var(--chat_post_back) !important;

}

.shareInput:focus {
    outline: none;
}

.shareHr {
    margin: 20px;
}

.shareBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; /* Allow flex items to wrap */
}

.shareOptions {
    display: flex;
    margin-left: 20px;
}

.shareOption {
    display: flex;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;
    color: var(--chat_text_color) !important;
}

.shareIcon {
    font-size: 18px;
    margin-right: 3px;
}

.shareOptionText {
    font-size: 14px;
    font-weight: 500;
    color: var(--chat_text_color) !important;

}

.shareButton {
    border: none;
    padding: 7px;
    border-radius: 5px;
    background-color: green;
    font-weight: 500;
    margin-right: 20px;
    cursor: pointer;
    color: white;
    /* border: 2px solid darkgreen; */
}
.shareInput {
    color: var(--chat_text_color) !important;
}
/* Media queries for responsiveness */

@media (max-width: 600px) {
    .share {
        width: 100%!important; /* Adjust width for smaller screens */
    }
    .shareOptionText {
        font-size: 12px;
        font-weight: 500;
        color: var(--chat_text_color) !important;
    }
    .shareOption {
        display: flex;
        align-items: center;
        margin-left: -15px;
        cursor: pointer;
    }
}
