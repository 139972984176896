.w-40px {
    width: 40px !important;
}
.imggg {
    max-width: 100%;
    height: auto;
}

img, svg {
    vertical-align: middle;
}

#payDark{
    background-color: var(--body_background);
}