
:root {
--swiper-navigation-color: #4CAF50; 
}

.rowTeam {
text-align: center;
padding: 2em 1em;
}

.h1Team {
width: 100%;
text-align: center;
font-size: 3.5em;
color: #1f003b;
color: var(--chat_text_color) !important;
}

.cardTeam {
box-shadow: 0 0 2.4em rgba(25, 0, 58, 0.1);
border-radius: 0.6em;
cursor: pointer;
transition: 0.3s;
background-color: #ffffff;
text-align: center;
padding: 2em 1em; 
margin: 2em; 
color: var(--chat_text_color) !important;
background-color: var(--card_color) !important;
}

.imgTeam-container {
width: 10em;
height: 10em;
background-color: rgb(37, 168, 37);
padding: 0.3em;
border-radius: 50%;
margin: 0 auto 2em auto;
overflow: hidden;
}

.imgTeam {
width: 100%;
border-radius: 50%;
object-fit: cover;
height: 100%;
}

.h3Team {
font-weight: 500;
margin: 0.5em 0;
}

.pTeam {
font-weight: 300;
text-transform: uppercase;
margin: 0.5em 0 2em 0;
letter-spacing: 2px;
}

.iconsTeam {
width: 50%;
min-width: 180px;
margin: auto;
display: flex;
justify-content: space-around; 
}

.aTeam {
text-decoration: none;
color: inherit;
font-size: 1.4em;
}

.cardTeam:hover {
background: linear-gradient(rgb(181, 220, 181), rgb(151, 189, 151));
color: #ffffff;
}

.cardTeam:hover .imgTeam-container {
transform: scale(1.15);
}

@media screen and (min-width: 768px) {
.columnTeam {
    flex: 0 0 50%;
    max-width: 50%;
}
}

@media screen and (min-width: 992px) {
.columnTeam {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding: 0 1em;
}

.rowTeam {
    padding: 2em;
}

.cardTeam {
    max-width: 400px;
}
}

@media screen and (max-width: 767px) {
.columnTeam {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 1em;
}

.rowTeam {
    padding: 1em;
}

.cardTeam {
    width: calc(100% - 2em);
    max-width: 100%;
    margin: 1em auto; 
}

.cardTeam:hover .imgTeam-container {
    transform: scale(1);
}
}

.swiper-container {
padding: 2em 0;
}

.swiper-slide {
display: flex;
justify-content: center;
}

.swiper-button-next,
.swiper-button-prev {
color: var(--swiper-navigation-color); 
}

.swiper-pagination-bullet-active {
background: #a3c9a3;
}

.swiper-pagination-bullet {
background: #4CAF50 !important; 
}
