.profile-name{
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    line-height: 1.25;
    color: black;
}
.text-primary-hover, button.text-primary-hover {
    color: #747579;
    transition: all 0.3s ease-in-out;
}
.small {
    font-weight: 400;
}
.text-reset {
    
    color: inherit !important;
}

.cardD{
    background-color: var(--chat_post_back);
}
#emailForCardDK{
    color: var(--body_color) !important;
}
#nameForCardDK{
    padding-top: 4px;
    color: var(--body_color);
}