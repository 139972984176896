.cover-photo {
    position: relative;
    max-height: 300px;
    overflow: hidden;
}

.cover-photo img {
    width: 100%;
    height: auto;
    display: block;
    margin: auto;
}

.profile-photo {

    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 20px;
}

.profile-photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    margin: auto;
}

.upload-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

input[type="file"] {
    background-color: var(--body_background);
    color: var(--chat_text_color);
    /* border: 1px solid var(--chat_text_color); */
}
.container,
h1,
h3,
h6,
label,
/* input[type="text"], */
.btn {
    color: var(--chat_text_color);
}

/* .btn-success {
background-color: #28a745;
    color: white;
    margin-top: 10px;
    font-size: 16px;
    width: 90px;
    height: 40px;
}

.btn-danger{
    color: white;
    margin-top: 10px;
    font-size: 16px;
    width: 90px;
    height: 40px;
} */