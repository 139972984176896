.keep-button {
    color: #0cbc87;
    background-color: rgba(12, 188, 135, 0.1);
    letter-spacing: 0.5px;
    position: relative;
    overflow: hidden;
    outline: 0;
    margin-bottom: 6px;
}
.keep-button:hover{
    background-color: #0cbc87;
}
.deleteDark{
    background-color: var(--body_background);
}
.cardHeadDark{
    background-color: var(--body_background);

}