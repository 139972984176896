.Game-container {
    display: flex;
    justify-content: space-between;
}

.left-component {
    width: 50%;
    margin-top: 20px;
}

.right-component {
    width: 50%;
    margin-top: 30px;
    margin-left: 30px;
}

/* .btn-primary, .btn-success {
        padding: 6px 12px; 
        border: none;
        border-radius: 5px; 
        cursor: pointer;
        font-size: 10px;
        transition: background-color 0.3s, color 0.3s; 
        margin-bottom: 10px;
        margin-top: 10px; 
    }
    
    .btn-primary {
        background-color: rgb(192, 189, 189);
        color: black;
        margin-left: 15px;
    }
    
    .btn-success {
        background-color: rgb(124, 233, 124);
        color: black;
        margin-left: 30px; 
    }
    
    .btn-primary:hover {
        background-color: rgb(72, 69, 69);
        color: white;
    }
    
    .btn-success:hover {
        background-color: rgb(10, 118, 12);
        color: white;
    } */

.ul-Badmintion {
    list-style: none;
    padding: 0;
}

.name-Badmintion {
    font-size: 13px;
    margin-top: 20px;
    margin-left: 0px;
    margin-right: 10px;
}

.profile-image {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-bottom: -10px;
    margin-right: 10px;
}


.svg-Badmintion {
    margin-right: 10px;
    width: 15px;
    height: 15px;
}

.li-Badmintion {
    font-size: 17px;
    margin: 10px 0;
}

.Badminton {
    font-size: 35px;
}

.badminton-info {
    text-align: left;
    margin-right: 0;
    margin-left: auto;
}

@media (max-width: 768px) {
    .Game-container {
        flex-direction: column;
    }

    .left-component,
    .right-component {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
    }

    .right-component {
        margin-top: 10px;
    }

    .btn-primary,
    .btn-success {
        width: 100%;
        margin-left: 0;
        padding: 3px 6px;
    }

    .btn-primary {
        margin-top: 15px;
    }

    .btn-success {
        margin-top: 0;
    }
}