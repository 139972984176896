.green-border {
    border-color: green !important;
}

.card-title {
    text-align: left;
}

.custom-input {
    width: 100%;
    height: 50px;
}

.selected-amenities-list {
    list-style-type: none;
    padding: 0;
}

.selected-amenities-list li {
    background-color: #ffffff;
    padding: 5px 10px;
    margin: 5px 0;
    border-radius: 5px;
}

/* input[type="text"], */
input[type="search"],
textarea,
select,
.phone-input .input{
    background-color: var(--body_background) !important;
    color: var(--chat_text_color);
    padding: 8px;
}

.Regester-form-control,
.Register-form-control,
.form-select .phoneInput {
    background-color: var(--body_background) !important;
    color: var(--chat_text_color) !important;
}
