.square {
 background-color:var(--chat_post_back) !important;
  width: 60px;
  height: 40px;
  margin: 20px;
  margin-bottom: 40px;
  border: 2px solid var(--chat_post_back);
}

.icon {
  margin-top: -9px;

  font-size: 29px;
  margin-left: -6px;
}

.Icons {
  margin-left: 140px;
}

.icon3 {

  margin-top: -11px;
  color: black;
  font-size: 29px;
  margin-left: -4px;
}

.icon2 {
  margin-top: -6px;
  color: blue;
  font-size: 25px;
  margin-left: -4px;
}


.button4 {
  font-family: inherit;
  font-size: 18px;
  background: white;
  padding: 0.8em 1.2em;
  display: grid;
  grid-template-columns: auto 0fr;
  align-items: center;
  border-radius: 15%;
  transition: all 0.3s;
}

.button4:hover {
  cursor: pointer;
  transform: translateY(-3px);
  box-shadow: 0px 8px 15px #65d284;
  grid-template-columns: auto 1fr;
}

.button4:active {
  transform: scale(0.95);
  box-shadow: 0px 2px 5px #31C15A;
}