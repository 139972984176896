
.login-button {
  background-color: #31C15A;
  color: #fff;
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 100px;
  text-decoration: none;
  transition: 0.3s background-color;
}

.login-button:hover {
  background-color: #237239;
}

.navbar-toggler {
  border: none !important;
  font-size: 1.25rem !important;
}

.navbar-toggler-icon{
  background-image: var(--navbar_toggler_icon) !important;
}

.offcanvas.show {
  background-color: var(--body_background) !important; /* لون الخلفية عند فتح القائمة */
}

.navbar-toggler:focus , .btn-close:focus{
  box-shadow: none !important;
  outline: none !important;
  background-color: var(--head_color) !important;
}

.nav-link {
  color: var(--chat_nav) !important;
  font-weight: 500 !important;
  position: relative !important;
}

.nav-link:hover,
.active {
  color: var(--chat_text_color) !important;
}

@media (min-width:991px) {
  .nav-link::before {
    content: "" !important;
    position: absolute !important;
    bottom: 0 !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    width: 0 !important;
    height: 2.5px !important;
    background-color: #31C15A !important;
    visibility: hidden !important;
    transition: 0.3s ease-in-out !important;
  }

  .nav-link:hover:before,
  .active::before {
    width: 100% !important;
    visibility: visible !important;
    color: var(--chat_text_color) !important;
  }
}

.navbar {
  background-color: var(--chat_post_back);
  height: 70px;
  margin: 20px;
  border-radius: 20px;
  padding: 0.5rem;
}

.logo {

  height: 52px;
  margin-left: 10px;
  border-radius: 50%;
}

.proImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.dropdown-toggle {
  color: var(--body_color) !important;
}

.dropdown-menu{
  background-color: var(--chat_post_back) !important;
}

.dropdown-item {
  background-color: var(--chat_post_back) !important;
  color: var(--chat_nav) !important;
  font-weight: 500 !important;
  position: relative !important;
}

.dropdown-item:hover,
.active {
  color: var(--chat_text_color) !important;
}

@media (max-width:450px) {
  .v {
    visibility: hidden;
  }
}

/*making bell shape with one div */
.bell {
  border: 2.17px solid var(--chat_nav);
  border-radius: 10px 10px 0 0;
  width: 13px;
  height: 15px;
  background: transparent;
  display: block;
  position: relative;
  top: -3px;
}

.bell::before,
.bell::after {
  content: "";
  background: var(--chat_nav);
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 2.17px;
}

.bell::before {
  top: 100%;
  width: 20px;
}

.bell::after {
  top: calc(100% + 4px);
  width: 7px;
}

/*container main styling*/
.notification {
  background: transparent;
  border: none;
  padding: 15px 15px;
  border-radius: 50px;
  cursor: pointer;
  transition: 300ms;
  position: relative;
  display: flex;

}

/*notifications number with before*/
.notification::before {
  content: "";
  color: white;
  font-size: 10px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #31C15A;
  position: absolute;
  right: 8px;
  top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/*container background hover effect*/
.notification:hover {
  background: rgba(170, 170, 170, 0.062);
}

/*container animations*/
.notification:hover>.bell-container {
  animation: bell-animation 650ms ease-out 0s 1 normal both;
}

/*bell ring and scale animation*/
@keyframes bell-animation {
  20% {
    transform: rotate(15deg);
  }

  40% {
    transform: rotate(-15deg);
    scale: 1.1;
  }

  60% {
    transform: rotate(10deg);
    scale: 1.1;
  }

  80% {
    transform: rotate(-10deg);
  }

  0%,
  100% {
    transform: rotate(0deg);
  }
}


.navbar-dashboard {
  width: 100% !important;
  margin-left: 0;
  border-radius: 0;
}

@media ((max-width:1800px)and (min-width: 768px)) {
  .dashboardItem {
    margin-left: 150px;
  }
}
.navbar.rtl {
  direction: rtl;
}

.navbar.rtl .navbar-brand {
  margin-right: auto;
  margin-left: 0;
}

.navbar.rtl .navbar-nav .nav-link {
  margin-left: 0;
  margin-right: 0.5rem;
}

.navbar.rtl .dropdown-menu {
  right: auto;
  left: 0;
}