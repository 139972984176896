.booking {
    min-height: 100vh;
    min-height: 100dvh;
    display: grid;
    padding: 10px;
    margin: auto;
    overflow:hidden;

}
.BookPageLink{
    text-decoration: none;
}
img {
    opacity:unset;
}
