.center {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    margin-top: 20px;
}

.btn-read-more {
    padding: 10px 30px;
    color: #31c15a;
    font-size: 16px;
    background-color: #fff;
    cursor: pointer;
}

.btn-read-more:hover {
    color: #0cb13a;
}