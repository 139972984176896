.profileBackground {
    width: 100%;
    max-height: 400px;
    border-radius: 4px 4px 4px 4px;
}

.big-profile-photo {
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 7px;
}

.profileInfo li {
    margin-left: 10px;
    font-size: 15px;
    font-weight: bold;
}

.ProfileName {
    font-weight: bold;
    font-size: 25px;
}

.bottomSideCard {
    outline: none;
    padding: 0;
    margin-bottom: -10px;
}


.bottomSideCard .nav li {
    margin-right: 40px;
}

.bottomSideCard .nav li a {
    text-decoration: none !important;
    padding: 20px 0;
}




.bottomSideCard .nav li a.active {
    color: rgb(97, 179, 97) !important;
    font-weight: bold !important;
}


a:hover {
    cursor: pointer;
}

@media (max-width : 523px) {
    .bottomSideCard .nav li {
        margin-right: 30px;
    }

    .bottomSideCard .nav li a {
        margin-left: 15%;
    }
}

@media (max-width: 991px) {

    a.active {
        border-bottom: solid;
        border-color: rgb(97, 179, 97);
        border-bottom-color: rgb(97, 179, 97);
    }
}

@media (max-width: 523px) {
    #responsive-nav li {
        width: 50%;
        /* margin: auto; */
    }

}

@media (max-width: 576px) {
    .profileAvatar {
        margin-left: auto;
        margin-right: auto;
    }
}


/* Apply changes for dark mode */

.bottomSideCard {
    background-color: var(--chat_post_back) !important;
}

.headerCard {
    background-color: var(--chat_post_back) !important;
}


.profileInfo li,
.ProfileName,
.bottomSideCard .nav li a
{
    color: var(--profile_header_name) !important;
}


.bottomSideCard .nav li a.active {
    color: rgb(97, 179, 97) !important;
}