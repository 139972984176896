.headerImg {
    background-image:linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.7)), url("https://asset.gecdesigns.com/img/wallpapers/football-stadium-with-night-sky-background-sports-stadium-with-light-effect-wallpaper-sr26062409-cover.webp");
    background-size:cover;
    background-repeat: no-repeat;
    height: 100vh;
    position: relative;
    }
  
    .headerContent{
      height: 100vh;
      z-index: 1;
      position: relative;
    }

    .headerText{
      position:absolute;
      left: 0;
      right: 0;
      text-align: center;
      color: white;
    }
    .par{
      font-weight: 100 !important;
    }
    @media (max-width:370px){
      .par{
        margin-top: 50px;
      }
  }
