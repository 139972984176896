.format{
    color:var(--body_color);
}


.form-check-input:checked {
    background-color: #31C15A !important;
    border-color: #0d6efd;

}

.form-check {
    display: block;
    min-height: 1.5rem !important;
    padding-left: 1.5em !important;
    margin-bottom: 0.7rem !important;
}
