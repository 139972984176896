#footerDark{
    color: var(--body_color) ;
}
#getConnectSpan{
    color: var(--body_color) ;
}

#pForFooter{
    color: var(--body_color) ;
}
.darkModeFooter{
    color: var(--body_color) ;
}
.BGdarkFooter  {
    color: var(--body_color) ;

}
.BGdarkFooter .testDark{
    color: var(--body_color) ;
}


ul {
    list-style: none;
    }
    
    .example-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    }
    
    .example-2 .icon-content {
    margin: 0 10px;
    position: relative;
    }
    
    .example-2 .icon-content .tooltip {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    padding: 6px 10px;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    font-size: 14px;
    transition: all 0.3s ease;
    }
    
    .example-2 .icon-content:hover .tooltip {
    opacity: 1;
    visibility: visible;
    top: -50px;
    }
    
    .example-2 .icon-content a {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: #4d4d4d;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    }
    
    .example-2 .icon-content a:hover {
    box-shadow: 3px 2px 45px 0px rgb(0 0 0 / 12%);
    }
    
    .example-2 .icon-content a svg {
    position: relative;
    z-index: 1;
    width: 30px;
    height: 30px;
    }
    
    .example-2 .icon-content a:hover {
    color: white;
    }
    
    .example-2 .icon-content a .filled {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: #000;
    transition: all 0.3s ease-in-out;
    }
    
    .example-2 .icon-content a:hover .filled {
    height: 100%;
    }
    
    .example-2 .icon-content a[data-social="linkedin"] .filled,
    .example-2 .icon-content a[data-social="linkedin"]~.tooltip {
    background-color: #0274b3;
    }
    
    .example-2 .icon-content a[data-social="github"] .filled,
    .example-2 .icon-content a[data-social="github"]~.tooltip {
    background-color: #24262a;
    }
    
    .example-2 .icon-content a[data-social="instagram"] .filled,
    .example-2 .icon-content a[data-social="instagram"]~.tooltip {
    background: linear-gradient(45deg,
        #405de6,
        #5b51db,
        #b33ab4,
        #c135b4,
        #e1306c,
        #fd1f1f);
    }
    
    .example-2 .icon-content a[data-social="youtube"] .filled,
    .example-2 .icon-content a[data-social="youtube"]~.tooltip {
    background-color: #ff0000;
    }
    
    #contact {
    margin-left: 20px;
    }
    
    
@media (max-width: 768px) {
    .example-2 {
      flex-wrap: wrap;
    }
    
    .example-2 .icon-content {
      margin: 10px;
    }
    
    .example-2 .icon-content a {
      width: 40px;
      height: 40px;
    }
    
    #contact {
      margin-left: 0;
      text-align: center;
    }
    .embed-responsive-21by9 iframe {
      width: 100%;
      height: auto;
    }

    }
