.custom-request-model-header{
    background-color: var(--chat_post_back);
    color:var(--body_color);

}
.custom-request-model-body{
    background-color: var(--chat_post_back);
    color:var(--body_color);
}
.model-select-form{
    background-color: var(--chat_post_back);
    color: var(--body_color) !important;;
}