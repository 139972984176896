.container-img{
  width:35%
}
.container-text{
  width: 35%;
}
.container-text p{
  color: var(--chat_text_color);
}

@media (max-width: 780px) {
  .container-img{
    width:0%
  }
  .container-text{
    width: 100%;
  }
  .container-text h1{
    text-align: center;
  }
  .container-text p{
    padding-left: 30px;
    padding-right: 30px;
  }
}
