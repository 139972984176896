.playCard {

    border-radius: 12px;
    overflow: hidden;
    position: relative;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    box-shadow: 0 1px 3px rgba(207, 207, 207, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 300ms;
    flex: 0 0 auto;
    width: 375px;
    height: 270px !important;
}

.card__description {
    color: white;
    font-size: 20px;
}

.playCard:hover {
    transform: translateY(-2px);
    box-shadow: 0 14px 28px rgba(83, 83, 83, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.card__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.4;
}

.playCard .content {
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    padding: 30px 70px 20px 20px;
    height: auto;
    bottom: 0;
    background: linear-gradient(transparent, rgba(224, 224, 224, 0.6));
    z-index: 1;
    color: #050505;
    top: 0px;
    font-weight: 600;
}

.playCard .title {

    font-size: 25px;
    color: #030303;
    margin: 0;
    top: 0px;
}

.button {
    position: absolute;
    bottom: 20px;
    right: 10px;
    bottom: 2px;
    padding: 1rem 2rem;
    font-family: inherit;
    font-weight: bold;
    font-size: 1rem;
    border-radius: 8px;
    margin: 1rem;
    border: 2px solid #31C15A;
    background: transparent;
    color: #dde9e0;
    background: #31C15A;
    transition: background 200ms ease-in, color 200ms ease-in;
    width: auto;
    height: auto;
}