@media screen and (min-width: 600px) {
    .posty {
        margin: auto;
        max-width: 60%;
    }
}

.posty {
    background-color: var(--chat_post_back) !important;
}

.card-footer {
    background-color: var(--chat_post_back) !important;
}

.accountName-post {
    color: #88a8d9 !important;
}

.post-time {
    color: #a29b9e !important;
}

.post-bodyy {
    color: var(--chat_text_color) !important;
}

.add-comment-textarea {
    background-color: var(--text_area_post) !important;
    color: #d4d4d4 !important;
    border: 1px solid var(--text_area_border) !important;
    padding: 8px;
    font-size: 16px;
    border-radius: 5px;
    /* Add border radius */
}

.add-comment-textarea::placeholder {
    color: var(--text_area_placeholder) !important;
}



