.fakepasswordicon {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
}
.input-group{
    color:var(--body_color)
}
#iconShowPass{
    color:var(--body_color)
}
.card-header{
    color:var(--body_color)
}
