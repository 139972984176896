.settings-profile {
    margin-top: 100px;
    
}

.avatar-img {
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.rounded-circle {
    border-radius: 50% !important;
}

.border-2 {
    border-width: 2px !important;
}

.nav-pills-primary-soft.flex-column .nav-item {
    margin-bottom: 3px;
    margin-right: 0;
}

.nav-pills-primary-soft .nav-item {
    padding: 0 !important;
    margin-right: 2px;
    margin-left: 2px;
    border-bottom: 0 !important;
}

.settings-button {
    background-color: #31C15A;
    border-color: #31C15A;
    letter-spacing: 0.5px;
    position: relative;
    overflow: hidden;
    outline: 0;
    margin-bottom: 6px;
    font-weight: 500;

}
.settings-button:hover{
    background-color: #31C15A;
    border-color: #31C15A;
}

.gap-4 {
    gap: 1.8rem !important;
}