.reservations_menu {
margin-bottom: 1rem;
}

.reservations-empty_title {
font-size: 24px; 
font-weight: bold;
margin-bottom: 20px;
}

.reservations-empty_icon {
margin-bottom: 20px; 
}

.tab-menu {
display: flex;
}

.tab-menu .p {
margin-right: 1rem;
text-decoration: none;
color: #333; 
color: var(--chat_text_color) !important;

}

.tab-menu_link--active {
font-weight: bold;
position: relative;

}

.reservations-empty {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: 50vh; 
}

.reservations-empty_body {
text-align: center;
}

.reservations-empty_body .pbook {
margin: 0; 
}


.colored-icon {
filter: invert(70%) sepia(10%) saturate(6582%) hue-rotate(97deg) brightness(95%) contrast(101%);
width: 60px; 
height: 60px;
}


.hr {
border: none;
border-top: 1px solid #b7b2b2; 
margin: 20px 0; 
}

.pbook {
color: gray;
margin-bottom: 30px;
}


.container-Booking {
width: 60%; 
margin: 0 auto; 
padding: 20px; 
border: 1px solid #ccc; 
border-radius: 8px; 
text-align: left; 
margin-top: 50px; 
background-color: var(--body_background) !important; 
color: var(--chat_text_color) !important;

}

@media screen and (max-width: 768px) {
.container-Booking {
width: 90vw; 
}
}

.tab-menu_link--active::after {
content: "";
position: absolute;
left: 0;
bottom: -15px; 
width: 100%; 
height: 2px; 
background-color: rgb(36, 184, 36); 
}


.Bookingcard{
width: 100%;
height: auto;
display: flex;
flex-direction: column;
max-width: 22em;
border: 1px solid #ccc;
border-radius: 0.5em;
overflow: hidden;
margin: 50px auto 0; 
background-color: #eaecf4;
background-color: var(--card_color) !important; 
padding: 1em;
}

.Bookingcard .card-img-top {
    max-width: 400px;
    max-height: 180px;
    margin: 0 auto; 
}

.History-card-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5em;
}

.text-section {
    flex-grow: 1; 
}

.vertical-line {
    width: 1px;
    background-color: #151515;
    margin: 0 1em;
}

.cta-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0.5em;
}

.History-card-text {
    font-size: small;
    margin-bottom: 0.5em; 
}

.ul {
    list-style-type: none;
    padding: 0; 
    margin: 0; 
}




