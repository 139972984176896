.backgroug {

  /* background-image: url('../../../../../public/assets/BackgroudBase.png'); */
  background-image: var(--backgroud--inputes) !important;
  /* background-image: url('../../../../../public/assets/AuthBGImage.png') ; */
  background-size: cover;
  background-repeat: no-repeat;
  height: 140vh;
  object-fit: cover;
}

.Regester-card {
  border: 0.80rem solid var(--chat_post_back);
  top: 0%;
}

.ooooo {
  background-color: var(--chat_post_back) !important;
}

.Regester-form-control {
  background-color: var(--chat_post_back) !important;
  padding: 20px;
  padding: 25px 15px;
  margin-bottom: 1.3rem;
}

.card2 {
  background-color: var(--chat_post_back);
}

.title7 {
  color: var(--body_color);
}

.text {
  color: var(--body_color);
}


.select {
  background-color: white !important;
}


.arrrr
{
  color:var(--arrow_to_home) !important;
}


/* Style the auth-language icon */
.auth-language .auth-language-icon {
  color: #007bff; /* Set the icon color to blue */
  font-size: 1.1rem; /* Adjust the font size as needed */
  transition: color 0.3s ease;
  margin-top: 10px !important; /* Adjust margin to move the icon down */
}


.auth-language .auth-language-icon:hover,
.auth-language .auth-language-icon:focus {
  color: #0056b3; /* Darken the blue color on hover/focus */
}

/* Customize the auth-language dropdown menu */
.auth-language .auth-language-dropdown {
  min-width: 120px; /* Reduce the minimum width */
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  overflow: hidden;
  margin-top: 5px !important;
}

/* Style the auth-language dropdown items */
.auth-language .auth-language-item {
  padding: 0.3rem 1rem; /* Reduce padding for smaller items */
  font-size: 0.9rem; /* Slightly reduce the font size */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.auth-language .auth-language-item:hover,
.auth-language .auth-language-item:focus {
  background-color: #f8f9fa;
  color: #007bff;
}

/* Auth-language divider style */
.auth-language .auth-language-divider {
  margin: 0.3rem 0; /* Reduce margin for a smaller divider */
}

