
#sidebarMenu{
  background-color: var(--chat_post_back);
}
  /* Sidebar */
  .sidebar {
    
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 58px 0 0; /* Height of navbar */
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
    width: 240px; /* Default width for larger screens */
    z-index: 600;
  }

  
  .sidebar .active {
    border-radius: 5px;
    box-shadow: 0 2px 5px 0 rgba(143, 53, 53, 0.16), 0 2px 10px 0 rgb(0 0 0 / 12%);
    background-color: #00B562 !important;
    border-width: 0cqh !important;
  }
  
  .sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: 0.5rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  }
  
  .button-side-bar{
    background-color: var(--chat_post_back) !important;
    color:var(--body_color) !important;
  }


  @media (min-width: 1800px) {
  .sidebar-container {
    width: 240px;
    /* Keep the sidebar width consistent with smaller screens */
  }

  .main-content {
    margin-left: 250px;
    /* Adjust the margin to accommodate the sidebar width */
  }
}


@media (max-width: 1799px) {
  .sidebar-container {
    float: left;
    width: 250px;
    /* Adjust width as needed */
  }

  .main-content {
    margin-left: 250px;
    /* Same width as sidebar */
  }
  @media (max-width: 749px) {
    .sidebar-container {
        float: left;
        width: 50px !important; /* Adjust width as needed */
      }
      #sidebarMenu{
        width: 80px;
      }
      
      .main-content {
        margin-left: 80px; /* Same width as sidebar */
        width:80%;
      }
  }
}
