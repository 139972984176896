.Distance {
    color: #31C15A;
}

.bookcard {
    transition: box-shadow 0.3s ease;
    height: 410px !important;
    background-color: var(--chat_post_back) !important;
}

.bookcard:hover {
    background: linear-gradient(170deg,var(--chat_post_back));
    box-shadow: 0px 0px 20px #31C15A;
}

.custom-ameneties-badge {

    background-color:var(--amenites_background_color);
    color:var(--body_color) !important;
    font-size: 1em !important;
    font-weight: 500 !important;
    margin-left: 10px;
    margin-top: 5px;
}

.heartcard {
    font-size: large !important;
    font-weight: 500 !important;
    border-radius: 9px !important;
    color: black;
}

.card-img-top {
    border-radius: 16px;
    height: 250px;
    @media (max-width: 1024px) {
        height: 200px; 
    }
}