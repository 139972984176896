.title4 {
    font-size: 13px !important;
color: var(--body_color) !important;
}

.button {
    margin-left: 170px;
}

@media (max-width:580px) {

    .title4 {
        margin-bottom: 0px !important;
        margin-left: 120px;
    }
}