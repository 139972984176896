.big-image {
    width: 600px !important;
    height: 390px !important;
}

.right-left-buttonss {
    top: 50%;
}



.custom-container {
    margin-top: 140px;
    margin-bottom: 100px;
}

/* .custom-container .carousel-inner img {
    width: 100%;
    height: 100%;
} */

#custCarousel .custom-list-inline {
    position: static;
    margin-top: 20px;
}

#custCarousel .custom-list-inline>li {
    width: 100px;
}

#custCarousel .custom-list-inline li img {
    display: block;
    opacity: 0.5;
}

#custCarousel .custom-list-inline li.active img {
    opacity: 1;
}

#custCarousel .custom-list-inline li:hover img {
    opacity: 0.75;
}

.carousel-item img {
    width: 80%;
}


@media (max-width: 768px) and (min-width:377px) {
    .big-image {
        height: 250px !important;
        max-width: 390px !important;
    }

    .right-left-buttonss {
        top: 45%;
    }

    #custCarousel .custom-list-inline>li {
        width: 65px;
    }
}

@media (max-width: 376px) {
    .big-image {
        height: 230px !important;
        max-width: 360px !important;
    }

    .right-left-buttonss {
        top: 45%;
    }

    #custCarousel .custom-list-inline>li {
        width: 65px;
    }
}