.upload-container {
    border: 1px solid#31a15c;
    background-color: #f3f3f3;
    padding: 20px;
}

.upload_dropZone {
    color: #31a15c;
    background-color: #fff;
    background-color: var(--body_background) !important;
    outline: 2px dashed #31a15c;
    outline-offset: -12px;
    transition: outline-offset 0.2s ease-out, outline-color 0.3s ease-in-out, background-color 0.2s ease-out;
}

.upload_dropZone.highlight {
    outline-offset: -4px;
    outline-color:#31a15c;
    background-color: #dff5f7;
}

.upload_svg {
    fill: #31a15c;
}

.btn-upload {
    color: #fff;
    background-color:#31a15c;
}

.btn-upload:hover,
.btn-upload:focus {
    color: #fff;
    background-color:#31a15c;
}

.upload_img {
    width: calc(33.333% - (2rem / 3));
    object-fit: contain;
}