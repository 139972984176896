.table-book-container {
    padding: 20px;
    border: 2px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    margin-bottom: 20px;
  }
  
  .table-book-container h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .calendar-container {
    width: 100%;
  }
  
  .booking-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .booking-overlay h2 {
    font-size: 28px;
    color: #fff;
    text-align: center;
  }
  
  /* Customize event colors */
  .rbc-event.available {
    background-color: #007bff; /* Blue for available events */
  }
  
  .rbc-event.joinable {
    background-color: #28a745; /* Green for joinable events */
  }
  
  .rbc-event.booked {
    background-color: #dc3545; /* Red for booked events */
  }
  
  /* Smaller table size */
  .rbc-calendar {
    font-size: 14px;
  }
  
  .rbc-time-header-content {
    font-size: 12px;
  }
  
  .rbc-time-view {
    border: 1px solid #ccc;
    border-top: none;
  }
  
  .rbc-time-header {
    border-bottom: 1px solid #ccc;
  }
  
  .rbc-event {
    border-radius: 4px;
    padding: 2px;
    margin-bottom: 2px;
  }
  
  .rbc-event-label {
    font-size: 12px;
  }
  
  .custom-calendar .rbc-month-view {
    padding: 20px;
  }
  
  .custom-calendar .rbc-header {
    background-color: #4F80E1;
    color: white;
    font-weight: bold;
  }
  
  .custom-calendar .rbc-day-bg {
    border: 1px solid #eee;
  }
  
  .custom-calendar .rbc-day-bg + .rbc-day-bg {
    border-left: 0;
  }
  
  .custom-calendar .rbc-day-slot {
    border: 1px solid #eee;
  }
  
  .custom-calendar .rbc-today {
    background-color: #BEE3F8;
  }
  
  .custom-calendar .rbc-selected {
    background-color: #4F80E1;
    color: white;
  }
  
  .custom-calendar .rbc-event {
    border: none;
    background-color: #51C994;
    color: black;
    padding: 2px 5px;
    border-radius: 3px;
  }
  
  .custom-calendar .rbc-event-label {
    display: none;
  }
  
  .custom-calendar .rbc-show-more {
    background-color: rgba(79, 128, 225, 0.1);
    border: none;
    color: #4F80E1;
    font-weight: bold;
    cursor: pointer;
  }
  