.book-now-container {
    display: flex;
  }
  
  .book-component {
    flex: 1;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 600px) {
    .book-now-container {
      display: block; /* Change to block layout on smaller screens */
    }
  
    .book-component {
      flex: none; /* Disable flex on smaller screens */
    }
    .book-Title {
      margin-top: 80px;
    }
  }
  