.search-playPage {
    margin-top: 100px;
    border-bottom: 1px solid black;


}

.all,
.container-fluid,
a.navbar-brand,
.form-search-play-page,
.button.btn-outline-success .text-inside-search-playPage {
    color: var(--chat_text_color) !important;
}

.mx-auto{

    color: var(--body_background) !important;
}

@media (max-width: 769px) {
    .navbar-custom {
        border-bottom: none;
    }

    .form-search-play-page {
        margin-left: -5px;
    }

    .SearchPlayPageContainer {
        border-radius: 25px;
        height: 120px;
        border-bottom: 1px solid black;
        margin-bottom: -25px;

    }
}