#eventH{
    color: rgb(22, 126, 22);
    margin-top: 80px!important;
}
@media (max-width: 768px) {
    /* .container {
        padding: 0 10px;
        margin-left: 40px;
    } */
    .table-container {
        overflow-x: scroll;
    }
}
.skipNowLink{
    color: rgb(22, 126, 22);
}