    .stadiumCardSkeleton {
        display: flex;
        flex-direction: row;
        border: 1px solid #ddd;
        padding-top: 40px;
        padding: 20px;
        border-radius: 10px;
    }
    .stadiumSkeletonLeftCol {
        flex: 1;
        margin-right: 20px;
    }
    
    .stadiumSkeletonRightCol {
        flex: 2;
    }
    
    .titleSkeleton {
        margin-bottom: 10px;
    }
    
    .ratingSkeleton {
        margin-bottom: 10px;
    }
    
    .buttonSkeleton {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    .userRatingSkeleton {
        margin-top: 20px;
        margin-bottom: 10px;
    }
