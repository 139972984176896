.all-posts-page {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh; */
    /* Ensures it takes up the full height of the viewport */
}
body{
    /* background-color: var(--chat_page_back) !important; */
}
.toggle-dark-mode-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    color: #333;
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    margin-top: 10px;
}

.toggle-dark-mode-button span {
    margin-left: 5px;
}

.toggle-dark-mode-button svg {
    margin-right: 5px;
}

/* Styling for dark mode */
.dark-mode .toggle-dark-mode-button {
    color: #fff;
}