.table-card {
    background-color:var(--chat_post_back) !important;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    overflow-x: auto;
    margin-top: 20px;
}
.panel-title{
    color: var(--body_color);
}

.tableofusers {
    border: 1px solid;
    border-color: #f2f2f2;
    table-layout: auto;
}


table.tableofusers tr th,
table.tableofusers tr td {
    padding: 12px 15px;
    vertical-align: middle;
}

table.tableofusers tr th:first-child {
    width: 60px;
}

table.tableofusers tr th:last-child {
    width: 100px;
}

.tabelBadge {
    color: black;
    font-size: 1em !important;
    font-weight: 500 !important;
}

.button-container {
    display: flex;
    justify-content: center;
}

.btnTable {
    font-size: 1em !important;
    background-color: #31C15A !important;
    font-weight: 500 !important;
}

.btnTable:hover {
    background-color: rgba(49, 193, 90, 0.5) !important;
}

.title-table {
    padding-left: 10px;
}

.pageNumber {
    justify-content: end;
}

.pagination.pageNumber .page-link {
    color: rgb(0, 0, 0);
}

.pagination.pageNumber .page-item.disabled .page-link {
    color: gray;


}

.pagination.pageNumber .page-item.active .page-link {
    color: white;

    background-color: rgba(49, 193, 90, 0.9);

    border-color: transparent !important;

}