.locationCont {
    width: 85% !important;
}

.eerrr {
    margin-top: 60px;
}

@media (max-width: 498px) {
    .locationCont {
        width: 95%;
    }

    .eerrr {
        margin-top: -30px;
    }

    .stadName {
        font-size: 30px;
    }

    .locationCont {
        width: 95% !important;
    }
}

.locationIframe {
    height: 250px;
}

@media (max-width: 498px) {
    .locationIframe {
        height: 200px;
    }
}