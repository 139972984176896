.Regester-form-control::placeholder {
  color: gray !important;
}

.register-form-control {
  color: black !important;
}


.register-form-control {
  height: 60px;
  /* Adjust the height as needed */
}

#iconShowPass {
  color: black !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer;
}

.input-eye-text {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 6rem;
  transform: translateY(-50%);
}


@media (min-width : 998px) and (max-width: 1191px) {
  .input-eye-text {
    right: 4rem;
    /* Adjust this value for smaller screens */
  }
}


@media (min-width : 2038px) and (max-width: 2498px) {
  .input-eye-text {
    right: 9rem;
    /* Adjust this value for smaller screens */
  }
}



@media screen and (max-width: 992px) {
  .input-eye-text {
    right: 3.7rem;
    /* Adjust this value for smaller screens */
  }
}

@media screen and (max-width: 768px) {
  .input-eye-text {
    right: 4.9rem;
    /* Adjust this value for smaller screens */
  }
}

@media screen and (max-width: 576px) {
  .input-eye-text {
    right: 2.9rem;
    /* Adjust this value for smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .input-eye-text {
    right: 2.9rem;
    /* Adjust this value for smaller screens */
  }
}

@media screen and (max-width: 360px) {
  .input-eye-text {
    right: 2.9rem;
    /* Adjust this value for even smaller screens */
  }
}

@media screen and (max-width: 320px) {
  .input-eye-text {
    right: 2.8rem;
    /* Adjust this value for the smallest screens */
  }
}

@media (min-width : 767px) and (max-width: 1044px) {
  .card2 {
    width: 400px;
    margin-left: -70px;
  }
}