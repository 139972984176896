#locModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

#locModalContent {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 50%;
    max-width: 500px;
}

.close {
    float: right;
    font-size: 28px;
    cursor: pointer;
}
