.bookingHeader {
    background-color: var(--chat_post_back);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    color:var(--body_color);
}

.nav-item.ml-auto {
    margin-left: 30px;
    font-weight: 700;
}

.group {
    display: flex;
    line-height: 28px;
    align-items: center;
    position: relative;
    max-width: 190px;
}
.ml-auto {
    margin-left: auto; 
}

.inputBookingSearch {
    width: 100%;
    height: 40px;
    line-height: 28px;
    padding: 0 1rem;
    padding-left: 2.5rem;
    border: 2px solid transparent;
    border-radius: 8px;
    outline: none;
    background-color: #f3f3f4;
    color: #0d0c22;
    transition: .3s ease;
}

.inputBookingSearch::placeholder {
    color: #9e9ea7;
}

.inputBookingSearch:focus,
input:hover {
    outline: none;
    border-color: #31C15A;
    background-color: #fff;
    box-shadow: 0 0 0 4px rgba(8, 207, 141, 0.363);
}

.iconSearch {
    position: absolute;
    left: 1rem;
    fill: #9e9ea7;
    width: 1rem;
    height: 1rem;
}