@media screen and (min-width:700px) and (max-width:999px){
    .w{
        width: 75%;
    }
}
@media screen and (min-width:1000px){
    .w{
        width: 50%;
    }
}

.backColor{
    background-color: var(--body_background) !important;
}

.text{
    color: var(--chat_text_color);
}

